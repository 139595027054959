import { safeFetchAPI } from '@/api';
import { AttributeSchema } from '@/schemas';
import { Attribute, FullContact } from '@/types';
import { z } from 'zod';
import { create } from 'zustand';

export type CustomAttributesStoreState = {
	loading: boolean;
	loaded: boolean;
	customAttributes: Attribute[] | undefined;

	loadCustomAttributes: (contact: FullContact) => Promise<void>;
	saveCustomAttributes: (contactID: string, customAttributes: Attribute[]) => Promise<void>;

	validateCustomAttributes: (customAttributes: Attribute[]) => ValidationResult;

	clearCustomAttributes: () => void;
};

type ValidResopnse = [true, undefined];
type InvalidResponse = [false, { [key: string]: string }[]];
type ValidationResult = ValidResopnse | InvalidResponse;

type OmitKeys = 'loadCustomAttributes' | 'clearCustomAttributes' | 'saveCustomAttributes' | 'validateCustomAttributes';
const defaults: Omit<CustomAttributesStoreState, OmitKeys> = {
	loading: false,
	loaded: false,
	customAttributes: undefined,
};

export const useCustomAttributesStore = create<CustomAttributesStoreState>((set, get) => ({
	...defaults,

	async loadCustomAttributes(contact: FullContact) {
		set({ loading: true });
		const [customAttributes, error] = await safeFetchAPI<Attribute[]>(`/contact/trait/:uid/${contact.contactID}`, { forceToken: true });
		if (error) {
			set({ loading: false });
			console.error(error);
			return;
		}

		// combine contact traits with contact.customAttributes
		// If a matching trait with same (key, parent, value, ts) exists don't add it
		const cloneMap: { [key: string]: boolean } = {};
		const combined = [...(customAttributes || []), ...(contact.customAttributes || []).map((attr) => ({ ...attr, pending: true }))].filter((attr) => {
			const key = `${attr.key}:::${attr.parent}:::${attr.value}`;
			if (cloneMap[key]) return false;
			cloneMap[key] = true;
			return true;
		});

		const sortedAttributes = combined?.sort((a, b) => (b?.ts ?? 0) - (a?.ts ?? 0));
		set({ loading: false, loaded: true, customAttributes: sortedAttributes });
	},

	async saveCustomAttributes(contactID: string, customAttributes: Attribute[]) {
		set({ loading: true });

		const [isValid, errors] = get().validateCustomAttributes(customAttributes);
		if (!isValid) {
			set({ loading: false });
			throw new Error(JSON.stringify(errors));
		}

		const [_, error] = await safeFetchAPI(`/contact/trait/:uid/${contactID}`, {
			method: 'PUT',
			payload: customAttributes,
		});

		if (error) {
			set({ loading: false });
			console.error(error);
			return;
		}

		set({ loading: false, customAttributes });
	},

	validateCustomAttributes(customAttributes: Attribute[]) {
		const errors: { [key: string]: string }[] = [];
		for (const attribute of customAttributes) {
			try {
				AttributeSchema.parse(attribute);
			} catch (e) {
				const attributeErrors: { [key: string]: string } = {};
				if (e instanceof z.ZodError) {
					e.errors.forEach((error) => {
						attributeErrors[error.path.join('.')] = error.message;
					});
				} else if (e instanceof Error) {
					attributeErrors._ = e.message;
				}
				errors.push(attributeErrors);
			}
		}

		const isValid = errors.length === 0;
		return isValid ? [true, undefined] : [false, errors];
	},

	clearCustomAttributes() {
		set({ ...defaults });
	},
}));

export default useCustomAttributesStore;
