import { safeFetchAPI } from '@/api';
import { userUtils } from '@/helpers';
import { PointsEventType, PointsTimeline, ViewableRedemption } from '@/types';
import { create } from 'zustand';

export type PointsTimelineStoreState = {
	loaded: boolean;
	loading: boolean;

	pointsTimeline: PointsTimeline | undefined;
	calculatedMetrics: CalculatedTimelineMetrics | undefined;

	loadPointsTimeline: (contactID: string, query?: PointsTimelineQuery) => Promise<void>;
	clearPointsTimeline: () => void;
};

export type PointsTimelineQuery = {
	start?: number;
	end?: number;
};

export const usePointsTimelineStore = create<PointsTimelineStoreState>((set, get) => ({
	loaded: false,
	loading: true,
	pointsTimeline: undefined,
	calculatedMetrics: undefined,

	async loadPointsTimeline(contactID, query) {
		const start = query?.start || -1;
		const end = query?.end || Math.floor(new Date().getTime() / 1000);

		set({ loading: true });

		const [pointsTimeline, error] = await safeFetchAPI<PointsTimeline>(`/contact/loyaltyPoints/timeline/:uid/${contactID}?start=${start}&end=${end}`, {
			forceToken: true,
		});
		if (error || !pointsTimeline) {
			set({ loading: false });
			console.error(error);
			return;
		}

		const calculatedMetrics = calculateMetrics(pointsTimeline);
		if (pointsTimeline.events.length) {
			// Sort newest to oldest
			pointsTimeline.events.sort((a, b) => b.timestamp - a.timestamp);
		}

		if (userUtils.debugMode()) {
			console.log('Points Timeline:', pointsTimeline);
			console.log('Calculated Metrics:', calculatedMetrics);
		}

		set({ calculatedMetrics, pointsTimeline, loaded: true, loading: false });
	},

	clearPointsTimeline: () => {
		set({
			loaded: false,
			loading: true,
			calculatedMetrics: undefined,
			pointsTimeline: undefined,
		});
	},
}));

export type TimelineEvent_EmployeeMetric = { id: string; name: string };
export type CalculatedTimelineMetrics = {
	eventTypes: PointsEventType[]; // List of all event types
	storeIDs?: number[]; // List of all store IDs
	employees?: TimelineEvent_EmployeeMetric[]; // List of all employees
	metrics: Record<string, number | string>;
};

function calculateMetrics(pointsTimeline: PointsTimeline): CalculatedTimelineMetrics {
	const metrics: Record<string, string | number> = {};

	const uniqueEvents = new Set<string>();
	const uniqueStoreIDs = new Set<number>();
	const employeesById: Record<string, string> = {};

	// Aggregate metric values
	const aggMetric = (key: string, value: number) => {
		const currentValue = (metrics[key] as number) || 0;
		metrics[key] = currentValue + (value ?? 0);
	};

	for (const event of pointsTimeline.events) {
		uniqueEvents.add(event.type);

		switch (event.type) {
			case 'sale':
				aggMetric('Spent', event.saleAmount);
				if (event.sale?.storeID) {
					uniqueStoreIDs.add(event.sale?.storeID);
				}
				if (event.sale?.employeeID) {
					employeesById[event.sale.employeeID] = event.sale.employeeName ?? 'Anonymous Employee';
				}
				break;
			case 'referral':
				aggMetric('Referral sales', event.value);
				break;

			case 'adjustment':
				// If there's an email, it's a staff adjustment
				if (event.adjustment?.email) {
					aggMetric('Points adjusted by staff', event.value);
					break;
				}
				// If there's no email and the note starts with 'From Campaign', it's a campaign gift adjustment
				if (event.adjustment?.note?.startsWith('From Campaign')) {
					aggMetric('Points boosted by audience', event.value);
					break;
				}
				// If there's no email && the note isn't from a campaign, it's a block adjustment
				aggMetric('Points adjusted by block', event.value);
				break;
			case 'boost':
				aggMetric('Points boosted by audience', event.value);
				break;
			case 'legacy':
				aggMetric('Legacy imported points', event.value);
				break;
			case 'visit':
				aggMetric('Visit Points', event.value);
				break;
			case 'collectible':
				aggMetric('Collectibles', event.value);
				break;
			case 'redemption': {
				const redemption = (event.redemption || {}) as ViewableRedemption;
				const lastRedemption = (metrics['Last redemption'] ?? 0) as number;

				if ((redemption?.ts || 0) > lastRedemption) {
					metrics['Last redemption'] = redemption?.ts || 0;
					metrics['Last redemption discount'] = `(${redemption.discountID || 'N/A'}) ${redemption.discountName || 'N/A'}`;
				}
				aggMetric('Points redeemed', event.value);
				break;
			}
			case 'expiration':
				aggMetric('Points expired', event.value);
				break;
		}
	}

	return {
		eventTypes: Array.from(uniqueEvents) as PointsEventType[],
		storeIDs: Array.from(uniqueStoreIDs),
		employees: Object.entries(employeesById).map(([id, name]) => ({ id, name })),
		metrics,
	};
}
