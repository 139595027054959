import { fetchAPI } from '@/api';
import { userUtils } from '@/helpers';
import { utils } from '@/helpers/utils';
import { QueueCustomer } from '@/legacy-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface QueueState {
	// Data
	hasQueue: boolean;
	hasLoadedHasQueue: boolean;
	anonymous: QueueCustomer[];
	members: QueueCustomer[];
	queue: QueueCustomer[]; // Combined queue
	isLoading: boolean;
	lastQueueUpdate: number;
	// Actions
	loadHasQueue: () => Promise<boolean>;
	loadQueue: (storeId: string) => Promise<void>;
	reset: () => void;
}

// Helper function to combine and sort queue members
const combineQueue = (anonymous: QueueCustomer[], members: QueueCustomer[]): QueueCustomer[] => {
	const allQueue: QueueCustomer[] = [
		...members.map((m) => ({
			...m,
			memberStatus: m?.loyaltyMember ? 1 : m.optOut ? 2 : 1,
			key: m.customerId,
			uid: m.uid,
		})),
		...anonymous.map((m) => ({
			...m,
			memberStatus: m?.loyaltyMember ? 1 : m.optOut ? 2 : 0,
			key: m.customerId,
			uid: m.uid,
		})),
	];

	utils.sort(allQueue, 'lastSeen');
	return allQueue;
};

export const useCustomerQueueStore = create<QueueState>()(
	devtools(
		(set, get) => ({
			// Initial state
			hasQueue: false,
			hasLoadedHasQueue: false,
			anonymous: [],
			members: [],
			queue: [],
			isLoading: false,
			lastQueueUpdate: 0,

			loadHasQueue: async () => {
				const hasQueue = ((await fetchAPI('/hasQueue/:uid')) ?? false) || (utils.isDevEnv() && userUtils.isAdmin());
				set({ hasQueue, hasLoadedHasQueue: true });
				return hasQueue;
			},

			// Load both anonymous and regular members
			loadQueue: async (storeId: string) => {
				const hasLoadedHasQueue = get().hasLoadedHasQueue;
				if (!hasLoadedHasQueue) {
					await get().loadHasQueue();
				}

				set({ isLoading: true });
				try {
					const [anonymous, members] = await Promise.all([fetchAPI(`/queue/anonymous/:uid/${storeId}`), fetchAPI(`/queue/members/:uid/${storeId}`)]);

					const anonymousData = anonymous || [];
					const membersData = members || [];

					set({
						anonymous: anonymousData,
						members: membersData,
						queue: combineQueue(anonymousData, membersData),
						lastQueueUpdate: Date.now(),
					});
				} finally {
					set({ isLoading: false });
				}
			},

			// Reset store to initial state
			reset: () => {
				set({
					anonymous: [],
					members: [],
					queue: [],
					isLoading: false,
					lastQueueUpdate: 0,
				});
			},
		}),
		{ name: 'queue-store' },
	),
);
