import { safeFetchAPI } from '@/api';
import { capturePosthogEvent, utils } from '@/helpers';
import { createRawPersona } from '@/helpers/createRawPersona';
import { FileProcessor, ProcessedFileData } from '@/pages/personas-import/FileProcessor';
import { BulkImportPesonasMetaSchema } from '@/schemas';
import { BulkImportPesonasMeta, RawContact } from '@/types';
import { create } from 'zustand';

export const ALLOWED_PERSONA_IMPORT_TYPES = ['.csv', '.xls', '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export interface BulkImportPersonasStore {
	// State
	isLoading: boolean;

	file: File | null;
	parseError: string;
	metaErrors: Record<keyof BulkImportPesonasMeta, string> | null;

	showingMainHeaders: boolean;
	progress: number;
	processedData: ProcessedFileData | null;
	uploadMeta: BulkImportPesonasMeta;

	// Actions
	setIsLoading: (isLoading: boolean) => void;
	setFile: (file: File | null) => void;
	setParseError: (error: string) => void;
	setShowingMainHeaders: (show: boolean) => void;
	setProgress: (progress: number) => void;
	setProcessedData: (data: ProcessedFileData | null) => void;
	setUploadMeta: (meta: BulkImportPesonasMeta) => void;
	setMetaErrors: (errors: Record<string, string> | null) => void;
	validateMeta: () => Record<string, string> | null;
	reset: () => void;

	// Main handlers
	handleFileLoad: (event: Event) => Promise<void>;
	parseFile: (file: File) => Promise<void>;
	processData: () => Record<string, Partial<RawContact>>;

	uploadFile: () => Promise<boolean>;
}

type InitialState = Omit<
	BulkImportPersonasStore,
	| 'setIsLoading'
	| 'setFile'
	| 'setParseError'
	| 'setShowingMainHeaders'
	| 'setProgress'
	| 'setProcessedData'
	| 'setUploadMeta'
	| 'setMetaErrors'
	| 'reset'
	| 'handleFileLoad'
	| 'parseFile'
	| 'processData'
	| 'uploadFile'
	| 'validateMeta'
>;

const initialState: InitialState = {
	isLoading: false,
	file: null,
	parseError: '',
	showingMainHeaders: true,
	progress: 0,
	processedData: null,
	metaErrors: null,
	uploadMeta: {
		fname: '',
		fdesc: '',
		allEmailOptins: false,
		allTextOptins: false,
		agreeTerms: false,
	},
};

export const useBulkImportPersonasStore = create<BulkImportPersonasStore>((set, get) => ({
	// Initial State
	...initialState,

	// Actions
	setIsLoading: (isLoading) => set({ isLoading }),
	setFile: (file) => set({ file }),
	setParseError: (error) => set({ parseError: error }),
	setShowingMainHeaders: (show) => set({ showingMainHeaders: show }),
	setProgress: (progress) => set({ progress: Math.min(Math.max(progress, 0), 100) }),
	setProcessedData: (data) => set({ processedData: data }),
	setUploadMeta: (meta) => set({ uploadMeta: meta }),
	setMetaErrors: (errors) => set({ metaErrors: errors }),

	validateMeta() {
		const store = get();
		const result = BulkImportPesonasMetaSchema.safeParse(store.uploadMeta);
		if (result.success) return null;
		// Return map of fieldName -> errorMessage.. Include "Required" for missing fields
		return result.error.errors.reduce(
			(acc, err) => {
				const key = err.path.join('.');
				acc[key] = err.message;
				return acc;
			},
			{} as Record<string, string>,
		);
	},

	reset: () => set({ ...initialState }),

	// Process the uploaded file
	parseFile: async (file: File) => {
		const store = get();
		store.setIsLoading(true);
		store.setParseError('');

		try {
			const processedData = await FileProcessor.processFile(file, (progress, pending) => {
				store.setProgress(progress);
				if (pending) store.setProcessedData(pending);
			});

			store.setProcessedData(processedData);
			store.setIsLoading(false);
			store.setProgress(100);
		} catch (error) {
			store.setParseError(error instanceof Error ? error.message : 'Failed to process file');
			store.setIsLoading(false);
			store.setProgress(0);
			store.setProcessedData(null);
		}
	},

	// Main upload handler
	handleFileLoad: async (event) => {
		const store = get();
		const file = (event.target as HTMLInputElement).files?.[0];

		if (!file) {
			store.setParseError('No file selected');
			return;
		}

		// Validate file type
		const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
		if (!ALLOWED_PERSONA_IMPORT_TYPES.includes(fileExtension)) {
			store.setParseError('Invalid file type. Please upload a CSV or Excel file.');
			return;
		}

		const fname = file.name.replace(fileExtension, '').replace(/[^a-zA-Z0-9]/g, '');

		capturePosthogEvent('Personas Bulk Import - File selected', { file: file.name });

		store.setFile(file);
		store.setUploadMeta({ ...store.uploadMeta, fname });
		await store.parseFile(file);
	},

	processData() {
		const store = get();
		const personas: Record<string, Partial<RawContact>> = {};

		const data = store.processedData?.data || [];
		const mapping = store.processedData?.mappings || {};

		const mapped = Object.keys(mapping).reduce(
			(mappings, fieldKey) => {
				const map = mapping[fieldKey];
				if (!map || map.ignore || !map.mappedTo) return mappings;
				if (map.mappedTo === 'Custom Attribute') {
					const attrs = Array.isArray(mappings['Custom Attributes']) ? mappings['Custom Attributes'] : [];
					mappings['Custom Attributes'] = [...attrs, fieldKey];
					return mappings;
				}
				mappings[map.mappedTo] = fieldKey;
				return mappings;
			},
			{} as Record<string, string | string[]>,
		);
		for (let index = 0; index < data.length; index++) {
			const persona = data[index];
			try {
				personas[`${index}`] = createRawPersona(persona as Record<string, any>, mapped);
			} catch (error) {
				console.error('Error processing persona:', error);
			}
			if (index % 10000 === 0) console.log(`Persona ${index}`);
		}
		return personas;
	},

	uploadFile: async () => {
		const store = get();
		if (!store.file) {
			utils.toaster?.addToast?.({ type: 'danger', message: 'No file selected', placement: 'top' });
			return false;
		}
		if (!store.processedData) {
			utils.toaster?.addToast?.({ type: 'danger', message: 'No file processed', placement: 'top' });
			return false;
		}

		const metaErrors = store.validateMeta();
		if (metaErrors) {
			const errorValues = Object.values(metaErrors);
			utils.toaster?.addToast?.({ type: 'danger', message: errorValues.join(', '), placement: 'top' });
			store.setMetaErrors(metaErrors);
			return false;
		}

		store.setIsLoading(true);

		const meta = store.uploadMeta;
		const personas = store.processData();
		if (Object.keys(personas).length === 0) {
			utils.toaster?.addToast?.({ type: 'danger', message: 'No personas to upload', placement: 'top' });
			store.setIsLoading(false);
			return false;
		}

		const [response, error] = await safeFetchAPI<{ audIDCreated: string }>('/users/files/:uid', {
			method: 'POST',
			payload: {
				status: true,
				ageGate: true,
				allTextOptins: meta.allTextOptins,
				allEmailOptins: meta.allEmailOptins,
				fname: meta.fname,
				fdesc: meta.fdesc,
				fuploader: utils.auth.getEmail(),
				personas: Object.values(personas),
			} as BulkImportPesonasMeta,
		});

		if (error) {
			utils.toaster?.addToast?.({ type: 'danger', message: error, placement: 'top' });
			store.setIsLoading(false);
			return false;
		}

		const { audIDCreated } = response ?? {};
		if (audIDCreated) {
			utils.toaster?.addToast?.({ type: 'success', message: 'Personas imported successfully', placement: 'top', duration: 12000 });
			utils.toaster?.addToast?.({ type: 'info', message: 'Creating an audience for this upload! (may take up to 12 hours) ', placement: 'top', duration: 12000 });
		}

		// When all is said and done we reset
		store.reset();
		return true;
	},
}));
