import { safeFetchAPI } from '@/api';
import { IntegrationOut } from '@/pages/integrations';
import { create } from 'zustand';

export type IntegrationtoreState = {
	loaded: boolean;
	loading: boolean;

	sources: IntegrationOut[] | undefined;

	loadIntegrations: () => Promise<void>;
	clearIntegrations: () => void;
};

export const useIntegrationSourcesStore = create<IntegrationtoreState>((set, get) => ({
	loaded: false,
	loading: true,
	sources: undefined,

	async loadIntegrations() {
		set({ loading: true });

		const [integrations, error] = await safeFetchAPI<IntegrationOut[]>('/sources/:uid/all', {
			forceToken: true,
		});
		if (error || !integrations) {
			set({ loading: false });
			console.error(error);
			return;
		}

		set({ sources: integrations, loaded: true, loading: false });
	},

	clearIntegrations: () => {
		set({
			loaded: false,
			loading: true,
			sources: undefined,
		});
	},
}));
