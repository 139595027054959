import { safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import { LegacyContactSearchQuery } from '@/legacy-types';
import { SearchHistory } from '@/pages/personas-staff-app/SearchHistory';
import { PaginatedContacts, TrimmedContact } from '@/types';
import { create } from 'zustand';

export type PersonasStoreState = {
	loaded: boolean;
	loading: boolean;

	queryHistory: SearchHistory | undefined;
	initHistory: (history?: SearchHistory) => void;

	data: PaginatedContacts;
	currentQuery?: LegacyContactSearchQuery;

	results: () => TrimmedContact[] | undefined;
	getContacts: (query: LegacyContactSearchQuery, useHistory?: boolean) => Promise<PaginatedContacts | undefined>;

	// Used for overrideing with search history
	revertToHistory: (searchQuery: string) => void;
};

export const usePersonaSearchStore = create<PersonasStoreState>((set, get) => ({
	loaded: false,
	loading: false,
	data: {
		total: 0,
		totalPersonas: 0,
		limit: 0,
		results: [],
		size: 0,
		timestamp: 0,
	},

	queryHistory: undefined,
	initHistory(initHistory) {
		const queryHistory = initHistory || new SearchHistory(utils.uid, { maxEntries: 5, maxAgeHours: 96 });
		set({ queryHistory });
	},

	revertToHistory(searchQuery) {
		const queryHistory = get().queryHistory;
		if (!queryHistory) return;

		const data = queryHistory.get(searchQuery);
		if (!data) return;

		set({ data, loaded: true });
	},

	results: () => {
		return get().data.results;
	},

	async getContacts(query: LegacyContactSearchQuery) {
		set({ loading: true });

		const [currentQuery, url] = craftQueryAndURL(query);
		const [data, error] = await safeFetchAPI<PaginatedContacts>(url, { forceToken: true });
		if (error || !data) {
			set({ loading: false });
			utils.toaster?.addToast?.({ message: error, type: 'danger', placement: 'top' });
			return;
		}
		set({ data, loading: false, loaded: true });
		set({ currentQuery });

		const searchValue = query.search?.trim();
		const queryHistory = get().queryHistory;
		if (!!searchValue && queryHistory && data.results?.length) {
			queryHistory.add(searchValue, data);
		}

		return data;
	},
}));

const craftQueryAndURL = (query: LegacyContactSearchQuery): [LegacyContactSearchQuery, string] => {
	const updatedQuery: LegacyContactSearchQuery = {
		start: query.start ?? 0,
		limit: query.limit ?? 10,
		search: query.search ?? '',
		sort: query.sort ?? 'signup',
		desc: query.desc ?? true,
		bypassOneCntOpt: query.bypassOneCntOpt ?? (typeof localStorage !== 'undefined' && localStorage.getItem('bypassOneCntOpt') === 'true'),
		nameCheck: query.nameCheck ?? false,
		init: query.init ?? false,
	};

	const params = new URLSearchParams({
		start: updatedQuery.start?.toString() ?? '',
		limit: updatedQuery.limit?.toString() ?? '',
		search: updatedQuery.search ?? '',
		sort: updatedQuery.sort ?? '',
		dir: updatedQuery.desc ? 'desc' : 'asc',
		bypassOneCntOpt: String(updatedQuery.bypassOneCntOpt),
		nameCheck: String(updatedQuery.nameCheck),
		init: String(updatedQuery.init),
	});

	const url = `/piis/:uid?${params.toString()}`;

	return [updatedQuery, url];
};

export default usePersonaSearchStore;
