import { safeFetchAPI } from '@/api';
import { EntryData } from '@/legacy-types';
import { MessageOut } from '@/types';
import { create } from 'zustand';

export type PersonaMessagesStoreState = {
	loaded: boolean;
	loading: boolean;

	// We store this as entrydata so we can grab it by ID when needed
	messageData: EntryData<MessageOut> | undefined;
	calculatedMetrics: CalculatedMessageMetrics | undefined;

	getMessages: () => MessageOut[];
	getMessage: (id: string) => MessageOut | undefined;

	loadMessages: (contactID: string) => Promise<void>;

	clearMessages: () => void;
};

export const usePersonaMessagesStore = create<PersonaMessagesStoreState>((set, get) => ({
	loaded: false,
	loading: true,

	messageData: undefined,
	calculatedMetrics: undefined,
	async loadMessages(contactID: string) {
		const [messages, error] = await safeFetchAPI<MessageOut[]>(`/contact/messages/:uid/${contactID}`, { forceToken: true });
		if (error || !messages) {
			console.error(error);
			return;
		}
		const messageData: EntryData<MessageOut> = { ids: [], entries: {} };
		const sortedMessages = messages.sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0));

		const carriers = new Set<string>();
		const directions = new Set<string>();
		const platforms = new Set<string>();

		for (const message of sortedMessages) {
			if (!message.SID) continue;
			messageData.ids.push(message.SID);
			messageData.entries[message.SID] = message;
			if (message.carrier) carriers.add(message.carrier);
			if (message.direction) directions.add(message.direction);
			if (message.platform) platforms.add(message.platform);
		}

		const calculatedMetrics: CalculatedMessageMetrics = {
			carriers: Array.from(carriers),
			directions: Array.from(directions),
			platforms: Array.from(platforms),
		};

		set({ messageData, calculatedMetrics, loaded: true, loading: false });
	},

	getMessages() {
		const messageData = get().messageData;
		return messageData?.ids.map((id) => messageData.entries[id]) ?? [];
	},

	getMessage(id: string) {
		const messageData = get().messageData;
		return messageData?.entries[id];
	},

	clearMessages() {
		set({
			loaded: false,
			loading: true,
			messageData: undefined,
		});
	},
}));

export type CalculatedMessageMetrics = {
	directions: MessageOut['direction'][];
	carriers: MessageOut['carrier'][];
	platforms: MessageOut['platform'][];
};

export default usePersonaMessagesStore;
