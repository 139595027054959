import { safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import { UserBlacklist } from '@/legacy-types';
import { create } from 'zustand';

export type PersonaBlacklistStore = {
	loaded: boolean;
	loading: boolean;

	blacklist: UserBlacklist | undefined;
	cleanedBlacklist: { [pii: string]: string } | undefined;

	asList: () => { type: string; value: string; key: string }[];

	loadBlacklist: () => Promise<UserBlacklist | undefined>;

	isBlacklisted: (pii: string, type?: string) => boolean;
	// addBlacklist: (pii: string, type?: string) => Promise<void>;
	removeBlacklist: (pii: string, type: string) => Promise<void>;
};

export const usePersonaBlacklist = create<PersonaBlacklistStore>((set, get) => ({
	loaded: false,
	loading: false,

	blacklist: undefined,
	cleanedBlacklist: undefined,

	asList() {
		const { blacklist } = get();
		if (!blacklist) return [];
		return Object.keys(blacklist || {})
			.filter((key: string) => (blacklist || {})[key]?.length)
			.map((key: string) => cleanBlacklistKey(key));
	},

	async loadBlacklist() {
		set({ loading: true });

		let [blacklist, error] = await safeFetchAPI<UserBlacklist>('/optout/:uid?dash=true');
		if (utils.isDevEnv()) {
			blacklist = localData();
		}
		if (error || !blacklist) {
			set({ loading: false });
			utils.toaster?.addToast?.({ message: error, type: 'danger', placement: 'top' });
			return;
		}

		const cleanedBlacklist: { [pii: string]: string } = {};
		for (const key in blacklist) {
			if (blacklist[key].length) {
				const { type, value } = cleanBlacklistKey(key);
				cleanedBlacklist[value] = type;
			}
		}

		set({ blacklist, cleanedBlacklist, loading: false, loaded: true });

		return blacklist;
	},

	isBlacklisted(pii, type) {
		const { cleanedBlacklist, blacklist } = get();
		if (!cleanedBlacklist || !blacklist) return false;
		if (cleanedBlacklist[pii] !== undefined) return true;
		if (type && blacklist[`${type}:::${pii}`]?.length) return true;
		return false;
	},

	async removeBlacklist(pii, type) {
		if (!pii) return utils.toaster?.addToast?.({ message: 'No PII provided', type: 'danger', placement: 'top' });
		const { blacklist, isBlacklisted } = get();
		if (!isBlacklisted(pii, type)) return utils.toaster?.addToast?.({ message: 'Persona is not blacklisted', type: 'danger', placement: 'top' });

		set({ loading: true });

		const params = [];
		if (type === 'PHONE') params.push(`phone=${utils.strify(pii || '').replace('+', '')}`);
		if (type === 'EMAIL') params.push(`email=${pii || ''}`);

		const [_, error] = await safeFetchAPI(`/manual/unblacklist/${utils.uid}?${params.join('&')}`);
		if (error) {
			set({ loading: false });
			utils.toaster?.addToast?.({ message: error, type: 'danger', placement: 'top' });
			return;
		}

		const newBlacklist: UserBlacklist = { ...blacklist };
		delete newBlacklist[`${type}:::${pii}`];
		set({ blacklist: newBlacklist, loading: false });
		utils.toaster?.addToast?.({ message: 'Persona has been un-blacklisted!', type: 'success', placement: 'top' });
	},
}));

export const cleanBlacklistKey = (key: string) => {
	const [type, value] = key.split(':::');
	return { type, value, key };
};

const localData = () => ({
	'PHONE:::3015028535': [-1, -23],
	'PHONE:::3137290152': [-1, -23],
	'PHONE:::3159498532': [-1, -23],
	'PHONE:::5617626425': [-1, -23],
	'PHONE:::8043807769': [-1, -23],
	'PHONE:::8406212548': [-1, -23],
});
